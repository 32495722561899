function initMap() {
    if ($(window).width() > 768) {
        var mapContainer = document.createElement('div');
        mapContainer.id = 'mapContainer';
        var mapElement = document.getElementById('map');

        if (mapElement) {
            mapElement.appendChild(mapContainer);

            Indra.maps.center = new google.maps.LatLng(Indra.maps.user_site_latitude, Indra.maps.user_site_longitude);

            Indra.map = new google.maps.Map(document.getElementById('mapContainer'), {
                center: Indra.maps.center,
                zoom: 8,
                styles: [
                    {
                        "featureType": "all",
                        "elementType": "labels.text.fill",
                        "stylers": [{"saturation": 36}, {"color": "#333333"}, {"lightness": 40}]
                    },
                    {
                        "featureType": "all",
                        "elementType": "labels.text.stroke",
                        "stylers": [{"visibility": "on"}, {"color": "#ffffff"}, {"lightness": 16}]
                    },
                    {
                        "featureType": "all",
                        "elementType": "labels.icon",
                        "stylers": [{"visibility": "off"}]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "geometry.fill",
                        "stylers": [{"color": "#fefefe"}, {"lightness": 20}]
                    },
                    {
                        "featureType": "administrative",
                        "elementType": "geometry.stroke",
                        "stylers": [{"color": "#fefefe"}, {"lightness": 17}, {"weight": 1.2}]
                    },
                    {
                        "featureType": "landscape",
                        "elementType": "geometry",
                        "stylers": [{"color": "#f5f5f5"}, {"lightness": 20}]
                    },
                    {
                        "featureType": "poi",
                        "elementType": "geometry",
                        "stylers": [{"color": "#f5f5f5"}, {"lightness": 21}]
                    },
                    {
                        "featureType": "poi.park",
                        "elementType": "geometry",
                        "stylers": [{"color": "#dedede"}, {"lightness": 21}]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.fill",
                        "stylers": [{"color": "#ffffff"}, {"lightness": 17}]
                    },
                    {
                        "featureType": "road.highway",
                        "elementType": "geometry.stroke",
                        "stylers": [{"color": "#ffffff"}, {"lightness": 29}, {"weight": 0.2}]
                    },
                    {
                        "featureType": "road.arterial",
                        "elementType": "geometry",
                        "stylers": [{"color": "#ffffff"}, {"lightness": 18}]
                    },
                    {
                        "featureType": "road.local",
                        "elementType": "geometry",
                        "stylers": [{"color": "#ffffff"}, {"lightness": 16}]
                    },
                    {
                        "featureType": "transit",
                        "elementType": "geometry",
                        "stylers": [{"color": "#f2f2f2"}, {"lightness": 19}]
                    },
                    {
                        "featureType": "water",
                        "elementType": "geometry",
                        "stylers": [{"color": "#e9e9e9"}, {"lightness": 17}]
                    }
                ]
            });

            new google.maps.KmlLayer(Indra.maps.kml, {
                suppressInfoWindows: true,
                preserveViewport: true,
                map: Indra.map
            });

            Indra.map.addListener('zoom_changed', function () {
                var zoom = Indra.map.getZoom();
                if (zoom >= 8) {
                    showHideInfoBox(true)
                } else if (zoom <= 7) {
                    showHideInfoBox(false);
                }
            });
            loadInfoBox();
        }
    }
}

function loadInfoBox() {
    var scriptInfoBox = document.createElement("script");
    scriptInfoBox.type = "text/javascript";
    scriptInfoBox.src = "assets/js/infobox.js";
    document.body.appendChild(scriptInfoBox);
    scriptInfoBox.onload = function () {
        loadMarker(Indra.maps.departments, Indra.maps.user_site_latitude, Indra.maps.user_site_longitude);
    }
}

function onclickNoInfoBoxMarker(marker) {
    marker.addListener('click', function() {
        for (var i in Indra.markers) {
            if (Indra.markers[i] == marker) {
                if (marker.icon == '/assets/img/pin-selected.png') {
                    Indra.markers[i].setIcon('/assets/img/pin.png');
                    SetSelected('indra_ao_list_Department', '');
                    $('#indra_ao_list_Department').selectpicker('refresh');
                } else {
                    marker.setIcon('/assets/img/pin-selected.png');
                    SetSelected('indra_ao_list_Department', marker.title);
                    $('#indra_ao_list_Department').selectpicker('refresh');
                }
            } else {
                Indra.markers[i].setIcon('/assets/img/pin.png');
            }
        }
    });
}

function addInfoBox(marker, department) {
    var count = department.count ? department.count : 0;

    // Append info Box to marker
    marker.ib = new InfoBox({
        content: department.marker,
        name: department.name,
        disableAutoPan: true,
        pixelOffset: new google.maps.Size(-70, -70),
        infoBoxClearance: new google.maps.Size(1, 1)
    });
    marker.ib.addListener('domready', function () {
        var ct = this;
        var w = $('.info-box', this.div_).innerWidth(),
            h = $('.info-box', this.div_).innerHeight(),
            val = w > h ? w : h;
        this.setOptions({
            pixelOffset: new google.maps.Size(-val / 2, -val / 2),
            boxStyle: {
                width: val + "px",
                height: val + "px"
            }
        });
        if (ct.getName() === $("button[data-id='indra_ao_list_Department']").attr('title')) {
            $(ct.div_).addClass('active');
        }
        $(ct.div_).on('click', function (){
            if ($(ct.div_).hasClass('active')) {
                $('.infoBox').removeClass('active');
                SetSelected('indra_ao_list_Department', '');
            } else {
                $('.infoBox').removeClass('active');
                $(ct.div_).toggleClass('active');
                SetSelected('indra_ao_list_Department', marker.ib.getName());
            }
            $('#indra_ao_list_Department').selectpicker('refresh');
            var $radius = $('#indra_ao_list_Radius');
            var value = $('#indra_ao_list_Department').val();
            $radius.val('');
            if (value) {
                $radius.prop('disabled', true);
            } else {
                $radius.prop('disabled', false);
            }
            $radius.selectpicker('refresh');
            filterOffer();
        });
    });
}
function SetSelected(elem, val){
    $('#'+elem+' option').each(function(i,d){
        if($.trim(d.text).toLowerCase() == $.trim(val).toLowerCase()){
            $('#'+elem).prop('selectedIndex', i);
        }
    });
}

function showHideInfoBox(bool) {
    for (var i in Indra.markers) {
        var ib = Indra.markers[i].ib;
        bool ? !ib.getVisible() ? ib.open(Indra.map, Indra.markers[i]) : '' : ib.getVisible() ? ib.close() : '';
    }
}

function clearMarkers() {
    for (var i in Indra.markers) {
        Indra.markers[i].setMap(null);
    }
}

function loadMarker(departments, user_site_latitude, user_site_longitude) {
    if (departments != undefined) {
        clearMarkers();

        for (var i in departments) {
            var department = departments[i];
            var latLng = {lat: department.latitude, lng: department.longitude};

            var marker = new google.maps.Marker({
                position: latLng,
                map: Indra.map,
                title: department.name,
                icon: '/assets/img/pin.png'
            });
            addInfoBox(marker, department);
            onclickNoInfoBoxMarker(marker);
            Indra.markers.push(marker);
        }
    }

    if (user_site_latitude != undefined && user_site_longitude != undefined) {
        Indra.map.setCenter(new google.maps.LatLng(user_site_latitude, user_site_longitude));
        Indra.map.setZoom(Indra.zoom);
    }
}

function updateMarker(departments) {
    if (departments != undefined) {
        showHideInfoBox(false);
        clearMarkers();
        var zoom = Indra.map.getZoom();
        for (var i in departments) {
            var department = departments[i];
            var marker = Indra.markers[i];
            var count = department.count ? department.count : 0;

            marker.title = department.name;
            marker.ib.setContent(department.marker);
            zoom >= 8 ? marker.ib.open(Indra.map, marker) : '';
            marker.setMap(Indra.map);
            Indra.markers[i].setIcon('/assets/img/pin.png');

            if (marker.ib.getName() === $("button[data-id='indra_ao_list_Department']").attr('title')) {
                marker.setIcon('/assets/img/pin-selected.png');
                // marker.ib.createInfoBoxDiv_();
                $(marker.ib.div_).addClass('active');
            }
        }
    }
}

function filterOffer() {
    var $filterOffer = $('.under-map');

    var newUrl = Routing.generate(Indra.ajax.searchAO, {page: 1}) + "&" + $filterOffer.serialize();
    $.ajax({
        url: newUrl,
        success: function (data, textStatus, jqXHR) {
            var $content = $('#content');
            var $errorContainerDate = $(".error-container");
            var infiniteScrollInstance = $content.data('infiniteScroll');

            if (infiniteScrollInstance) {
                infiniteScrollInstance.reset();
                infiniteScrollInstance.initSpinner();
            }

            if (data.prev_data_url) {
                infiniteScrollInstance.options.prev_data_url = data.prev_data_url;
            }
            if (data.next_data_url) {
                infiniteScrollInstance.options.next_data_url = data.next_data_url;
            }
            if (data.status) {
                $('.form-group', $filterOffer).each(function () {
                    $(this).removeClass('has-error');
                    $(this).find('p.help-block.text-danger').remove();
                });
                $errorContainerDate.html('');

                if (data.status == 'success') {
                    $content.html(data.response);
                }

                if (data.status == 'error-form') {
                    if (data.errors && data.form_prefix) {
                        for (var i in data.errors) {
                            var error = data.errors[i];
                            if (error.length > 0) {
                                var $formGroup = $('#' + data.form_prefix + '_' + i);
                                var $formGroupError = $formGroup;
                                $formGroup.parent().addClass('has-error');
                                if ('StartYear' == i || 'EndYear' == i) {
                                    $formGroupError = $formGroup.parent();
                                    $formGroupError.parent().find('.help-block').remove();
                                }
                                for (var j in error) {
                                    if (i == "StartYear" || i == "EndYear") {
                                        $errorContainerDate.addClass("has-error")
                                        $errorContainerDate.append($('<p class="help-block text-danger"></p>').text(error[j]));
                                    } else {
                                        $formGroupError.append($('<p class="help-block text-danger"></p>').text(error[j]));
                                    }
                                }
                            }

                        }
                    }
                } else {
                    $(".form-group").find(".has-error").each(function () {
                        $(this).removeClass("has-error");
                    });
                }
            }

            if (!data.is_home) {
                history.replaceState(null, '', newUrl);
            }

            updateMarker(data.departments);

            if (data.count_total != undefined) {
                $('[data-maps="count-total"]').html(data.count_total);
            }
        },
        error: function () {

        }
    });
}

Indra.map = {};
Indra.markers = [];
Indra.zoom = 8;

jQuery(document).ready(function () {
    var attr = {size: 10};
    if ($(window).width() < 768) {
        attr.mobile = true;
    }

    $('[data-selectpicker]').selectpicker(attr);

    function immat_type(immat, type) {
        if (immat.length == 0 && immat.length > 12) {
            return false;
        }

        switch (type) {
            case 'SIV':
                return (immat.match(/^[A-Z]{2}(\-{1})(\d{3})(\-{1})[A-Z]{2}$/));
            case 'FNI':
                return (immat.match(/(^[1-9]{1}(\d{0,3})[A-Z]{1,2}|^[1-9]{1}(\d{0,2})[A-Z]{1,3})([01345678]{1}[0-9]{1}|2[AB0-9]{1}|9[0-5]{1}|97[1-8]{1})$/)) && (immat.indexOf('I') <= 0) && (immat.indexOf('O') <= 0);
            case 'CYCLO':
                return (immat.match(/^[A-Z]{1,2}(\s)(\d{2,3})(\s)[A-Z]{1,2}$/));
            case 'ETRAN':
                return (immat.match(/^[a-zA-Z0-9\-]*$/));
            default:
                return false;
        }
    }

    var $aoShort = $('#ao_short');
    var $controlImmat = $('[data-control="immat"]', $aoShort).first();
    var $numberplateErrors = $('#numberplate_errors');
    var $typeImmat = $('[data-type="immat"]', $aoShort);
    var controlImmatType = '[data-type="immat"]:checked';
    var $modalButton = $("#modalFilter [data-control='submit']");
    var $favoriteFilterForm = $("form.modal-content");
    var $buttonOpenModal = $(".under-map-end button");
    var $buttonSubmitModel = $("button[data-control='submit']");
    var $buttonModifyFilter = $(".modify-filter");


    /* Modal and favorite filter treatment */

    $buttonModifyFilter.click(function (e) {
        e.preventDefault();
        var idFilter = $(this).attr("data-id");
        var newUrl = Routing.generate(Indra.ajax.favoriteFilterGet, {"filter_id": idFilter});
        $.ajax({
            url: newUrl,
            success: function (data) {
                $("#modalFilter").modal("show");
                $("#indra_filter_name").val(data.data.name);
                if (data.data.alert) {
                    $("#indra_filter_haveAlert_0").trigger("click");
                } else {
                    $("#indra_filter_haveAlert_1").trigger("click");
                }
                $("#indra_filter_email").val(data.data.email);
                $("#indra_filter_phone").val(data.data.phone);
                $buttonSubmitModel.attr("data-id", idFilter);
            }
        });
    });

    $('.access-table-id-link').on('click','tr',function() {
        window.location.href = Routing.generate('dashboard_redirect_to_ao', {"id": $(this).attr('id')});
    });

    $buttonOpenModal.click(function () {
        $buttonSubmitModel.attr("data-id", "")
    });

    $modalButton.on("click", $modalButton, function (e) {
        e.preventDefault();
        $(this).parent().parent().parent().find(".has-error").each(function(i){
            $(this).removeClass("has-error");
            $(this).find(".help-block").remove();
        });
        var newUrl = Routing.generate(Indra.ajax.favoriteFilter, {}) + "?" + $filterOffer.serialize() + "&" + $favoriteFilterForm.serialize() +
            "&indra_ao_list[Department]=" + $("#indra_ao_list_Department :selected").text() +
            "&indra_ao_list[Brand]=" + $("#indra_ao_list_Brand :selected").text() +
            "&indra_ao_list[Model]=" + $("#indra_ao_list_Model :selected").text();

        if ($buttonSubmitModel.attr("data-id") != "") {
            newUrl += "&filter_id=" + $buttonSubmitModel.attr("data-id");
        }

        $.ajax({
            url: newUrl,
            success: function (data) {
                if (data.status == 'error-form') {
                    if (data.errors && data.form_prefix) {
                        for (var i in data.errors) {
                            var error = data.errors[i];
                            if (error.length > 0) {
                                var $formGroup = $('#' + data.form_prefix + '_' + i).closest('.form-group');
                                var $formGroupError = $formGroup;
                                $formGroup.addClass('has-error');
                                for (var j in error) {
                                    if ($formGroupError.has("p.help-block").length == 0)
                                        $formGroupError.append($('<p class="help-block text-danger"></p>').text(error[j]));
                                }
                            }
                        }
                    }
                } else {
                    window.location.replace(Routing.generate(Indra.ajax.searchAO) + '?filter_id=' + data.data.filterId);
                }
            }
        });
    });

    $controlImmat.on('input', function () {
        $(this).val(function (_, val) {
            return val.toUpperCase();
        });
        $numberplateErrors.tooltip('destroy');
    });

    $typeImmat.on('change', function () {
        var $self = $(this);
        if ($self.is(':checked')) {
            $controlImmat.prop('placeholder', $self.prop('title'));
        }
    });

    $aoShort.on('submit', function (e) {
        var value = $controlImmat.val().trim();
        var type = $(controlImmatType, $aoShort).first().val();

        if (!immat_type(value, type)) {
            $controlImmat.tooltip('show');
            return false;
        }
    });

    $numberplateErrors.tooltip('show');
    setTimeout(function () {
        $numberplateErrors.tooltip('destroy');
    }, 10000);

    $('.news-flash').delay(2500).slideDown(1000);

    var $filterOffer = $('.under-map');

    $filterOffer.find('select').on('change', filterOffer);
    $filterOffer.find('#indra_ao_list_StartYear, #indra_ao_list_EndYear').on('keyup change', function () {
        var val = $(this).val();
        if (val.length == 4 || val == "") {
            filterOffer();
        }
    });

    // VRADE search: Check if the values make sense, and wait 2 seconds for the values to stop changing to avoid spamming AJAX calls
    $filterOffer.find('#indra_ao_list_VradeStart, #indra_ao_list_VradeEnd').on('keyup change', function () {
        const valStart = $filterOffer.find('#indra_ao_list_VradeStart').val();
        const valEnd = $filterOffer.find('#indra_ao_list_VradeEnd').val();
        if (valStart < 0 || valEnd < 0 || (valStart > valEnd && valEnd > 0))
            return ;
        setTimeout(function() {
            if (valStart === $filterOffer.find('#indra_ao_list_VradeStart').val() && valEnd === $filterOffer.find('#indra_ao_list_VradeEnd').val() ) {
                filterOffer();
            }
        }, 2000);
    });

    $('#indra_ao_list_Department').on('change', function (e) {
        var $radius = $('#indra_ao_list_Radius');
        var value = $(this).val();
        $radius.val('');

        if (value) {
            $radius.prop('disabled', true);
        } else {
            $radius.prop('disabled', false);
        }

        $radius.selectpicker('refresh');
    });

    $('form[name="indra_ao_list"][data-target]').first().on('submit', function () {
        var target = $(this).data('target');
        $(target).modal('show');

        return false;
    });


    if ($('#table_id').size() > 0) {

        $("a[href='/administration/dashboard/'] button").click(function () {
            $(location).attr("href", $(this).parent().attr("href"));
        });
        //=== DataTable (Back-Office)
        var columnDefs = [
            {
                "targets": "optionnal",
                "visible": false
            }
        ];

        if ($(window).width() < 768) {
            columnDefs = [
                {"targets": [2], "visible": false},
                {"targets": [3], "visible": false}
            ];

            for (i = 7; i < $("th").length; i++) {
                columnDefs.push(
                    {"targets": [i], "visible": false}
                );
            }
        }

        var table_id = $('#table_id').DataTable({
            "language": Indra.internationalization.datatable.fr,
            "processing": true,
            "serverSide": true,
            columnDefs: columnDefs,
            "ajax": {
                "url" : Indra.ajax.dashboard_pagination,
                "type" : "POST"
            },
            "scrollX": true
        });
        new $.fn.dataTable.Buttons(table_id, {
            buttons: [
                'colvis'
            ]
        });
        table_id.buttons().container().appendTo('#table_id_wrapper .col-sm-6:eq(0)');
        // catch submit button filter to populate visible columns
        $("#indra_dashboard_filter").on("click", function (e) {
            e.preventDefault();

            $("#indra_dashboard_columnsVisibility").val(table_id.columns().visible().join(";"));

            // submit form
            $(this).parents("form").submit();
        });

        $(".dataTables_length select").on("change", function () {
            $("#indra_dashboard_rowsVisibility").val($(this).val());
        });

        if ($("#indra_dashboard_rowsVisibility").val() != "") {
            $("#table_id_length select").val($("#indra_dashboard_rowsVisibility").val()).trigger('change');
        }
        //=== End DataTable (Back-Office)
    }

    var radio_checked = [];
    $(".radio-button input").on("click", function () {
        var radio_input_checked = $(this).parent().parent().find('input:checked');
        var radio_input_not_checked = $(this).parent().parent().find('input:not(":checked")');

        if (radio_checked.indexOf(radio_input_checked.attr("id")) == -1 ) {
            radio_checked.push(radio_input_checked.attr("id"));
            if(radio_checked.indexOf(radio_input_not_checked.attr("id")) != -1){
                radio_checked.splice(radio_checked.indexOf(radio_input_not_checked.attr("id")), 1);
            }
        } else {
            $(this).prop("checked", false);
            radio_checked.splice(radio_checked.indexOf(radio_input_checked.attr("id")), 1);
        }
    });

    // Filtre marque model

    var $marque = $('#'+Indra.ajax.brand_selector);
    var $model = $('#' + Indra.ajax.model_selector);

    if($marque.val() == "" || $model.find("option").length < 2)  {
        $model.prop("disabled", true);
    }
    // When sport gets selected ...
    $marque.change(function() {
        // ... retrieve the corresponding form.
        var $form = $(this).closest('form');
        // Simulate form data, but only include the selected sport value.
        var data = {};
        data["Brand"] = $('#'+Indra.ajax.brand_selector+' :selected').html();
        data["brand"] = $('#'+Indra.ajax.brand_selector+' :selected').html();
        data["RequestModel"] = "Model";
        // Submit data via AJAX to the form's action path.
        $.ajax({
            url : Indra.ajax.brand_model_route,
            type: $form.attr('method'),
            data : data,
            success: function(html) {

                // Replace current position field ...
                $model.prop("disabled",false);
                $model.html(
                    // ... with the returned one from the AJAX response.
                    $(html).find('#' + Indra.ajax.model_selector).html()

                );
                // Position field now displays the appropriate positions.
                $model.selectpicker("refresh");
            }
        });
    });

});

if($("#map").length > 0) {
    window.onload = function () {
        setTimeout(function () {
            var $apiKey = Indra.maps.api_key;
            $.getScript("https://maps.googleapis.com/maps/api/js?sensor=true&callback=initMap&key=" + $apiKey);
        }, 2000);
    }
}

// Filtre marque model

var $marque = $('#'+Indra.ajax.brand_selector);
var $model = $('#' + Indra.ajax.model_selector);

if($marque.val() == "" || $model.find("option").length < 2)  {
    $model.prop("disabled", true);
}
// When sport gets selected ...
$marque.change(function() {
    // ... retrieve the corresponding form.
    var $form = $(this).closest('form');
    // Simulate form data, but only include the selected sport value.
    var data = {};
    data["Brand"] = $('#'+Indra.ajax.brand_selector+' :selected').html();
    data["brand"] = $('#'+Indra.ajax.brand_selector+' :selected').html();
    data["RequestModel"] = "Model";
    // Submit data via AJAX to the form's action path.
    $.ajax({
        url : Indra.ajax.brand_model_route,
        type: $form.attr('method'),
        data : data,
        success: function(html) {

            // Replace current position field ...
            $model.prop("disabled",false);
            $model.html(
                // ... with the returned one from the AJAX response.
                $(html).find('#' + Indra.ajax.model_selector).html()

            );
            // Position field now displays the appropriate positions.
            $model.selectpicker("refresh");
        }
    });
});


// Echappement du caractère espace sur l'input de la page d'accueil pour les FNI

var $indraAoShortNumberPlate = $("#indra_ao_short_numberPlate");
var $indraAoShortType1 = $("#indra_ao_short_type_1");

$indraAoShortNumberPlate.keypress(function (e) {
    if($indraAoShortType1.prop("checked")) {
        var keycode = e.which | e.keyCode ;
        if(keycode == 32) {
            return false;
        }
    }
});

$indraAoShortType1.on("click", function(){
    if($(this).prop("checked")) {
        $indraAoShortNumberPlate.val(($indraAoShortNumberPlate.val()).split(' ').join(""))
    }
});

$indraAoShortNumberPlate.on("change" , function() {
    if($indraAoShortType1.prop("checked")) {
        $indraAoShortNumberPlate.val(($indraAoShortNumberPlate.val()).split(' ').join(""))
    }
});
