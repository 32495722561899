(function ($, Indra) {
    Indra.notificationCenter = {
        'infos': [],
        'errors': [],
        'setInfo': function (infos) {
            if (!Array.isArray(infos)) {
                infos = [infos];
            }

            for (var i in infos) {
                this.infos.push(infos[i]);
                $.notify({
                    icon: 'icon-chat-2-1 text-success',
                    message: infos[i]
                }, {
                    type: 'info',
                    delay: 15000
                });
            }

        },
        'setError': function (errors, delay) {
            if (!Array.isArray(errors)) {
                errors = [errors];
            }
            delay = typeof delay === "number" ? delay : 30000
            for (var i in errors) {
                this.errors.push(errors[i]);
                $.notify({
                    icon: 'icon-delete-1 text-danger',
                    message: errors[i]
                }, {
                    type: 'danger',
                    delay: delay
                });
            }
        },
        'init': function () {
            this.setInfo(Indra.notifs.infos);
            this.setError(Indra.notifs.errors);
        }
    };

    $(document).ready(function () {
        Indra.notificationCenter.init();
    });
})(jQuery, Indra);