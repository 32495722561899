(function ($) {
    var $hideForm = $('.map-header .ribbon .pull-right');

    $hideForm.on('click', function () {
        var $hiddenElements = $('.map');

        if ($hiddenElements.is(':hidden')) {
            var show = '.map-pull-up';
            var hide = '.map-pull-down';
            $hiddenElements.slideDown();
        } else {
            var show = '.map-pull-down';
            var hide = '.map-pull-up';
            $hiddenElements.slideUp();
        }

        $(hide).hide();
        $(show).show();
    });
})(jQuery);