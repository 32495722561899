(function ($, window) {
    var Indra = window.Indra || {};
    Indra.notifs = {
        infos: [],
        errors: []
    };
    Indra.ajax = {
        searchAO: undefined,
        favoriteFilter: undefined,
        favoriteFilterGet: undefined,
        dashboard_pagination:undefined,
        brand_selector :undefined,
        model_selector:undefined,
        brand_model_route:undefined
    };
    Indra.attachments = {};
    Indra.infiniteScroll = {
        pager: 1
    };
    Indra.maps = {
        kml: undefined,
        coordinates: undefined,
        departments: undefined,
        center: undefined,
        user_site_latitude: undefined,
        user_site_longitude: undefined,
        api_key: undefined,
    };

    Indra.internationalization = {
        datatable: {
            fr: {
                "sProcessing": "Traitement en cours...",
                "sSearch": "Rechercher&nbsp;:",
                "sLengthMenu": "Afficher _MENU_ &eacute;l&eacute;ments",
                "sInfo": "Affichage de l'&eacute;l&eacute;ment _START_ &agrave; _END_ sur _TOTAL_ &eacute;l&eacute;ments",
                "sInfoEmpty": "Affichage de l'&eacute;l&eacute;ment 0 &agrave; 0 sur 0 &eacute;l&eacute;ment",
                "sInfoFiltered": "(filtr&eacute; de _MAX_ &eacute;l&eacute;ments au total)",
                "sInfoPostFix": "",
                "sLoadingRecords": "Chargement en cours...",
                "sZeroRecords": "Aucun &eacute;l&eacute;ment &agrave; afficher",
                "sEmptyTable": "Aucune donn&eacute;e disponible dans le tableau",
                "oPaginate": {
                    "sFirst": "Premier",
                    "sPrevious": "Pr&eacute;c&eacute;dent",
                    "sNext": "Suivant",
                    "sLast": "Dernier"
                },
                "oAria": {
                    "sSortAscending": ": activer pour trier la colonne par ordre croissant",
                    "sSortDescending": ": activer pour trier la colonne par ordre d&eacute;croissant"
                },
                "buttons": {"colvis": "Afficher les colonnes"}
            }
        }
    };

    window.Indra = Indra;

    if (($(window).height() + 100) < $(document).height()) {
        $('#top-link-block').removeClass('hidden').affix({
            // how far to scroll down before link "slides" into view
            offset: {top: 100}
        });
    }
})(jQuery, window);