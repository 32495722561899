(function ($) {
    $(document).ready(function () {
        //== Mechanism to animate images
        var $groupDetailImages = $('[data-group="detail-images"]');

        $('[data-control="detail-carousel"]', $groupDetailImages).on('click', function () {
            var target = '[data-id="@id"]'.replace('@id', $(this).data('target'));

            $('[data-id]:not(.off)').addClass('off');
            $(target, $groupDetailImages).removeClass('off');
            $('[data-control].off').removeClass('off');
            $(this).addClass('off');
        });
        //==\ mechanism

        var $controlDeleteProposition = $('[data-control="delete-proposition"]');
        var $targetEmptyProposition = $('[data-target="empty-proposition"]');
        var $first = $('#indra_proposition_amount_first');
        var $second = $('#indra_proposition_amount_second');
        var $form = $('form[name="indra_proposition"]');
        $controlDeleteProposition.on('change', function (e) {
            var checked = $(this).prop('checked');
            $targetEmptyProposition.prop('readonly', checked);

            if ($controlDeleteProposition.is(':checked') && $first.val() != "" && $second.val() != "") {
                $form.submit();
            }
        });

        $second.on('paste', function (e) {
            e.preventDefault();
            e.stopPropagation();
        });

        if ($(window).width() < 768) {
            $('.bxslider').bxSlider({
                'controls': false,
                'pager': false
            });
        }

        if ($('#indra_ao_abuse_comment').length == 0) {
            $('form[name="indra_ao_abuse"]').on('change', function () {
                if ($(this).find(':selected').val().length > 0) {
                    $(this).submit();
                }
            });
        }

        // Get the modal
        var modalToken = document.getElementById("modal_token");

        // Get the button that opens the modal
        var btn = document.getElementById("indra_proposition_save");

        // Get the <span> element that closes the modal
        var span = document.getElementsByClassName("close")[0];

        var propositionForm = $('form[name="indra_proposition"]');

        //**************************************************************************************
        //* hover sur les step
        //**************************************************************************************
        $("#modal-step-one .is-valid").hover(function () {
            $(this).fadeOut(100);
            $(this).fadeIn(500);
        });

        //**************************************************************************************
        //* On vérifie la validité de la session avant de submit le form
        //**************************************************************************************
        propositionForm.submit(function (event) {
            if (Indra.ajax.isSessionValid === 'true'
                || Indra.ajax.isSessionValid === 1
                || Indra.ajax.isSessionValid === '1'
            ) {
                return true;
            } else {
                $('#token_process button').addClass('shake')
                setTimeout(function () {
                    $('#token_process button').removeClass("shake")  }, 400);
                return false;
            }

        });

        //**************************************************************************************
        //* accès à la validation de la session au click sur le bouton tonken_process
        //**************************************************************************************
        $('#token_process button').on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            modalToken.style.display = "block";
        });

        //**************************************************************************************
        //* When the user clicks anywhere outside of the modal, close it
        //**************************************************************************************
        window.onclick = function (event) {
            if (event.target == modalToken) {
                modalToken.style.display = "none";
                // on supprime le message d'erreur de l'email
                if (document.getElementById('error_email_token') !== null) {
                    document.getElementById('error_email_token').remove()
                }
            }
        }

        //****************************************
        //* AJAX1 => Manage validation proposition by code => send code
        //********************************************
        $('#indra_proposition_send_token').on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();

            //* Si la value du chammp email est un email on fait l'appel ajax
            var indraPropositionEmail = $('#indra_proposition_email');
            if (typeof indraPropositionEmail.val() == 'string' && indraPropositionEmail.val().length > 0
                && isEmail(indraPropositionEmail.val()) === true
            ) {
                $.ajax({
                    url: Indra.ajax.sendEmailForGetToken,
                    type: 'POST',
                    data: {
                        email: indraPropositionEmail.val(),
                        ao: $('#aoId').val(),
                    },
                    error: function (jqXHR) {
                        var flashMessageError= [jqXHR.responseJSON.data];
                        Indra.notificationCenter.setError(flashMessageError, 1000)
                    },
                    success: function (html) {

                        var $btn = $('#indra_proposition_send_token'),
                            $step = $btn.parents('.modal-body-token'),
                            stepIndex = $step.index(),
                            $pag = $('.modal-header-token span').eq(stepIndex);

                        nextStep($step, $pag);
                        var flashMessageNotifSuccess = ['Le code de vérification vient d\'être envoyé à l\'email renseigné'];
                        Indra.notificationCenter.setInfo(flashMessageNotifSuccess)
                        if (document.getElementById('error_email_token') !== null) {
                            document.getElementById('error_email_token').remove()
                        }
                    }
                });
            } else {
                if (indraPropositionEmail.get().length > 0 && typeof indraPropositionEmail.get(0) === 'object') {
                    if (document.getElementById('error_email_token') == null) {
                        // on ajoute le message d'erreur si l'email est vide
                        indraPropositionEmail.get(0).insertAdjacentHTML(
                            'afterend',
                            '<div id="error_email_token" style="color: red">Veuillez entrer un email</div>'
                        );
                    } else {
                        // si le message existe déjà on vient ajouter et supprimer la class shake pour pouvoir avoir l'effet à chaque click
                        $('#error_email_token').addClass('shake')
                        setTimeout(function () {
                            $('#error_email_token').get(0).classList.remove("shake")
                        }, 200)
                    }
                }
            }
        });

        //**************************************************************************************
        //* NEXT STEP function
        //**************************************************************************************
        function nextStep($step, $pag)
        {
            // animate the step out
            $step.addClass('animate-out-to-left');

            // animate the step in
            setTimeout(function () {
                $step.removeClass('animate-out-to-left is-showing')
                    .next().addClass('animate-in-from-right');
                $pag.removeClass('is-active is-invalid').addClass('is-valid')
                    .next().addClass('is-active');
            }, 600);

            // after the animation, adjust the classes
            setTimeout(function () {
                $step.next().removeClass('animate-in-from-right')
                    .addClass('is-showing');
            }, 1200);
        }

        //**************************************************************************************
        //* Check email
        //**************************************************************************************
        function isEmail(myVar)
        {
            // La 1ère étape consiste à définir l'expression régulière d'une adresse email
            var regEmail = new RegExp('^[0-9a-z._-]+@{1}[0-9a-z.-]{2,}[.]{1}[a-z]{2,5}$','i');

            return regEmail.test(myVar);
        }

        //**************************************************************************************
        //* Check Token
        //**************************************************************************************
        function isToken(myVar)
        {
            // La 1ère étape consiste à définir l'expression régulière du token
            // var regToken = new RegExp('^\d+$','i');
            var regToken = new RegExp('^[a-z0-9]+$','i');
            // var regEmail = new RegExp('^\d{4}$','i');

            return regToken.test(myVar);
        }

        //**************************************************************************************
        //* PREVIOUS STEP function
        //**************************************************************************************
        function previousStep($step, $pag)
        {
            // animate the step out
            $step.addClass('animate-out-to-right');

            // animate the step in
            setTimeout(function () {
                $step.removeClass('animate-out-to-right is-showing')
                    .prev().addClass('animate-in-from-left');
                $pag.removeClass('is-active is-valid is-invalid')
                    .prev().removeClass('is-valid is-invalid').addClass('is-active');
            }, 600);

            // after the animation, adjust the classes
            setTimeout(function () {
                $step.prev().removeClass('animate-in-from-left')
                    .addClass('is-showing');
            }, 1200);
        }

        //**************************************************************************************
        //* Si la session est expirée au click sur la checkbox on shake le text session expiré
        //**************************************************************************************
        var checkboxDeleteProposition = $('#indra_proposition_delete').siblings(".checkbox-clone");
        checkboxDeleteProposition.click(function () {
            if (Indra.ajax.isSessionValid !== 'true') {
                $('#token_process button').addClass('shake')
                setTimeout(function () {
                    $('#token_process button').removeClass("shake")  }, 400);
            }
        });

        //*******************************************************************************
        // Si la checkbox de suppression est checked au chargement de la page on décoche
        //*******************************************************************************
        if ($('input#indra_proposition_delete').is(':checked')) {
            $('input#indra_proposition_delete').prop("checked", false)
        }

        //*******************************************************************************
        //* PREVIOUS STEP => quand on click sur l'étape 1 on change les icon d'étape et on repasse à l'étape 1
        //*******************************************************************************
        var modalStepOne = $('#modal-step-one');
        modalStepOne.click(function () {
            if ($(this).hasClass("is-valid")) {
                var $step = $('.modal-body-token.step-2'),
                    stepIndex = $step.index(),
                    $pag = $('.modal-header-token span').eq(stepIndex);

                previousStep($step, $pag);
            }
        });

        //**********************************************
        //* AJAX2 => quand on envoie le token
        //**********************************************
        $('#store_token').on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();

            var indraPropositionToken = $('#indra_proposition_token');
            if (typeof indraPropositionToken.val() == 'string' && indraPropositionToken.val().length > 0
                && isToken(indraPropositionToken.val()) === true
            ) {
                $.ajax({
                    url: Indra.ajax.sendTokenForValidation,
                    type: 'POST',
                    data: {
                        token: indraPropositionToken.val(),
                        ao: $('#aoId').val(),
                    },
                    error: function (jqXHR) {
                        var flashMessageError= [jqXHR.responseJSON.data];


                        if (
                            document.getElementById('error_format_token') !== null
                            || document.getElementById('error_proposition_token') !== null
                        ) {
                            $('#error_format_token').remove()
                            $('#error_proposition_token').remove()
                        }

                        Indra.notificationCenter.setError(flashMessageError, 1000)
                        if (document.getElementById('error_proposition_token') == null) {
                            // on ajoute le message d'erreur si l'email est vide
                            indraPropositionToken.get(0).insertAdjacentHTML(
                                'afterend',
                                '<div id="error_proposition_token" style="color: red">'+flashMessageError+'</div>'
                            );
                            $('#error_proposition_token').addClass('shake')
                            setTimeout(function () {
                                $('#error_proposition_token').get(0).classList.remove("shake")
                            }, 200)
                        }
                    },
                    success: function (html) {
                        $('#modal-step-two').removeClass('is-active').addClass('is-valid');
                        var flashMessageSuccess= ['Session validée'];
                        Indra.ajax.isSessionValid = 'true'
                        if (document.getElementById('error_proposition_token') !== null) {
                            document.getElementById('error_proposition_token').remove()
                        }
                        $('#token_process').hide();
                        $('#indra_proposition_delete').prop("disabled", false)
                        // var infoSession = $('.info-session')
                        setTimeout(function () {
                            modalToken.style.display = "none";
                            Indra.notificationCenter.setInfo(flashMessageSuccess);
                            // if (infoSession.length > 0) {
                            //     $('<p class="info-session">Session valide</p>').insertAfter(infoSession)
                            //     infoSession.remove()
                            //     $('.info-session').removeClass("shake").addClass('shake')
                            // }
                        }, 1000)
                    }
                });
            } else {
                if (indraPropositionToken.get().length > 0 && typeof indraPropositionToken.get(0) === 'object') {
                    if (document.getElementById('error_proposition_token') !== null) {
                        $('#error_proposition_token').remove()
                    }
                    if (document.getElementById('error_format_token') == null) {
                        // on ajoute le message d'erreur si l'email est vide
                        indraPropositionToken.get(0).insertAdjacentHTML(
                            'afterend',
                            '<div id="error_format_token" style="color: red">Format du code invalide</div>'
                        );
                    } else {
                        // si le message existe déjà on vient ajouter et supprimer la class shake pour pouvoir avoir l'effet à chaque click
                        $('#error_format_token').addClass('shake')
                        setTimeout(function () {
                            $('#error_format_token').get(0).classList.remove("shake")
                        }, 200)
                    }
                }
            }
        });

        //**************************************************************************************
        //* start of ao edit panel on /annonce/{id}?from=bo
        //**************************************************************************************

        $(".collapsible").on('click', function () {
            if ($(this).hasClass("active")) {
                $(this).removeClass("active")
            } else {
                $(this).addClass("active")
            }
            $(this).next(".content").slideToggle(150)
        });

        //**************************************************************************************
        //* end of ao edit panel on /annonce/{id}?from=bo
        //**************************************************************************************
    });
})(jQuery);
