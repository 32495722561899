(function ($) {
    if ($("form[name='indra_ao']").find('.form-group.has-error').length > 0) {
        var typePrice = $("select[name='indra_ao[typePrice]']").val();
        $('.input-numbers i').text(typePrice === '' ? 'TTC': typePrice);
    }
    $("select[name='indra_ao[typePrice]']").on('change', function () {
        $('.input-numbers i').empty();
        $('.input-numbers i').text($(this).val());
    });

    function addAttachmentForm($collectionHolder, $newAttachmentBlock)
    {
        var prototype = $collectionHolder.data('prototype');
        var index = $collectionHolder.data('index');

        if ( index < 4 ) {
            if (index === 3) {
                $("[data-control='add-document']").parent().hide();
            }

            if (index === 0 && Indra.attachments.useRuleAttachment === '1') {
                var change = 'id="indra_ao_attachments___name___description" readonly value="' + Indra.attachments.labelOfFirstAttachement + '"'
                var prototype = prototype.replace('id="indra_ao_attachments___name___description"', change)
                var removeDeleteButton = '<div class="remove-document-container">\n' +
                    '        <div class="corner text-success" data-control="remove-document">\n' +
                    '            <i class="icon-bin-2"></i>\n' +
                    '        </div>\n' +
                    '    </div>'
                var prototype = prototype.replace(removeDeleteButton, '')
            }

            var newForm = prototype.replace(/__name__/g, index);
            $collectionHolder.data('index', index + 1);
            var $newFormBlock = $('<div class="attached-document on"></div>').append(newForm);
            $newAttachmentBlock.before($newFormBlock);

            $newFormBlock.on("dragenter",function (e) {
                e.stopImmediatePropagation();
                $("[data-target='documents-container-dropzone']").css('border', '4px dashed #97be0d').css("z-index","1");
            });
        }
    }

    function forceDestructionField()
    {
        var inputTechnicalFeasibiityRepair = $("#indra_ao_technicalFeasibilityRepair");
        //When loading page, if TNR then disable all fields except yes
        if (inputTechnicalFeasibiityRepair.val() == 'TNR') {
            $('input[name="indra_ao[canDestruct]"]').prop('disabled', true);
            $('#indra_ao_canDestruct_0').prop('disabled', false);
            $('#indra_ao_canDestruct_0').prop('readonly', true);
        }
        //When changing values from the TechnicalFeasibiityRepair select
        inputTechnicalFeasibiityRepair.on('change', function (val) {
            if ($(this).val() == 'TNR') {
                //Update canDestruct Field, disable the rest
                $('#indra_ao_canDestruct_0').prop('checked', true);
                $('input[name="indra_ao[canDestruct]"]').prop('disabled', true);
                $('#indra_ao_canDestruct_0').prop('disabled', false);
                $('#indra_ao_canDestruct_0').prop('readonly', true);
            } else {
                $('input[name="indra_ao[canDestruct]"]').prop('readonly', false).prop('disabled', false);
            }
        });

    }

    function checkFirstAttachment(firstAttachment, removeButtonFirstAttachment)
    {
        if (Indra.attachments.useRuleAttachment === '1') {
            firstAttachment.attr('readonly', 'readonly')
            removeButtonFirstAttachment.remove()
            firstAttachment.val(Indra.attachments.labelOfFirstAttachement)
        }
    }

    $(document).ready(function () {

        //Manage TechnicalFeasibiityRepair and canDestruct fields
        forceDestructionField();

        //== Button Helper
        $(".btn-help").on("click", function () {
            if ($(this).hasClass('more')) {
                $(".publish-photos-help-container .help").css('display', 'inline');
                $(".publish-photos-help-container .less").show();
                $(".publish-photos-help-container .more").hide();
            } else {
                $(".publish-photos-help-container .help").css('display', 'none');
                $(".publish-photos-help-container .less").hide();
                $(".publish-photos-help-container .more").show();
            }
        });

        //== Attached Document upload
        var $collectionHolder;
        var $addAttachmentLink = $('<a data-control="add-document" href="#" class="add-attached-document"><i class="text-success icon-download-1"></i> ' + Indra.attachments.link + '</a>');
        var $newAttachmentBlock = $('<div class="add-attached-document-container on"></div>').append($addAttachmentLink);

        $collectionHolder = $('fieldset[data-target="documents-container"]');
        $collectionHolder.append($newAttachmentBlock);
        $collectionHolder.data('index', $collectionHolder.find(':file').length);
        $addAttachmentLink.on('click', function (e) {
            e.preventDefault();
            addAttachmentForm($collectionHolder, $newAttachmentBlock);
        });

        var firstAttachment = $("#indra_ao_attachments_0_description")
        var removeButtonFirstAttachment = firstAttachment.siblings(".remove-document-container")
        checkFirstAttachment(firstAttachment, removeButtonFirstAttachment)


        // When we put the ao in draft we delete the empty attachments
        $("#indra_ao_draft").on('click', function (e) {
            var attachmentBock = $(".attached-document");
            attachmentBock.each(function () {
                var attachment = $(this).find('input[type="file"][id*="indra_ao_attachments"]')
                var fileAttachment = attachment[0].files[0];
                if (!fileAttachment) {
                    $(this).remove();
                }
            });
        });

        $collectionHolder.on('click' , '[data-control="remove-document"]', function (e) {
            e.preventDefault();
            var $closestDocument = $(this).closest('.attached-document');
            $closestDocument.remove();
            if ($collectionHolder.data('index') == 4) {
                $("[data-control='add-document']").parent().show();
            }
            $collectionHolder.data('index', $collectionHolder.data('index') - 1);
        });

        $collectionHolder.on('change','input[type="file"][id*="indra_ao_attachments"]' ,function (e) {
            var file = this.files[0];
            if (file) {
                var value = file.name;
                var $closestDocument = $(this).closest('.attached-document');
                $closestDocument.find('[data-target="document-name"]').val(value);

                $idFileInput = $("[data-target='documents-container'] > .attached-document.on").size() - 1;
                $input = $(this);
                $input.removeClass("input-documents-dropzone");
            }
        });

        //==\ attached document

        //== Minimum price
        var $minimumPrice = $('[data-control="minimum-price"]');
        var $minimumPriceTarget = $('[data-target="minimum-price"]');

        $minimumPrice.on('change', function () {
            var checked = $(this).prop('checked');
            if (checked) {
                $minimumPriceTarget.prop('disabled', true);
                $minimumPriceTarget.val('');
            } else {
                $minimumPriceTarget.prop('disabled', false);
            }
        });
        //==\ minimum price

        //== Upload image
        var $uploadGroup = $('[data-upload="group"]');
        var $drawingModal = $('[data-upload="drawing-modal"]').first();
        var $drawCover = $('[data-upload="draw-cover"]').first();
        var $clearDraw = $('[data-upload="clear"]').first();
        var $downloadDraw = $('[data-upload="download"]').first();

        var drawSketch = null;
        var upload_img = false;

        if ($drawCover.length > 0) {
            $drawCover.sketch({
                defaultSize: 15
            });
        }
        drawSketch = $drawCover.data('sketch');

        function clearDraw()
        {
            drawSketch.actions = [];
            drawSketch.redraw();
        }

        $clearDraw.on('click', clearDraw);
        $downloadDraw.on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();

            var id = drawSketch.canvas.data('image').id;
            var data = {
                foreground: drawSketch.el.toDataURL("image/png")
            };

            $.ajax({
                url: Indra.ajax.merge_img + id,
                type: 'POST',
                dataType: 'json',
                data: data,
                success: function (response) {
                    var uploadGroupById = $('[data-upload="group"][data-id="' + id + '"]');
                    if (response.thumbnail) {
                        var $img = uploadGroupById.find('[data-upload="img"]');
                        var $newImg = $img.clone();
                        $newImg.prop('src', response.thumbnail);
                        $img.replaceWith($newImg);
                    }

                    if (response.image) {
                        drawSketch.canvas.data('image', response.image);
                        uploadGroupById.find('[data-upload="edit"]').data('full', response.image);
                    }

                    $drawingModal.modal('hide');
                    clearDraw();
                }
            });
        });

        $uploadGroup.each(function () {
            var $self = $(this);
            var id = $self.data('id');

            var $input = $('input[type="file"]', $self);
            var $image = $('[data-upload="img"]', $self);
            var $error = $('[data-upload="error"]', $self);
            var $update = $('[data-upload="update"]', $self);
            var $remove = $('[data-upload="remove"]', $self);
            var $edit = $('[data-upload="edit"]', $self);

            $edit.data('full', Indra.upload.images[id].full);

            $input.on('change', function (e) {
                if (e.target.files[0]) {
                    if (upload_img) {
                        alert('Une image est en cours de chargement.');
                        $input.val('');
                    } else {
                        upload_img = true;
                        $self.removeClass('complete');
                        $self.addClass('loading');

                        resizeUploadImage(e.target.files[0], $self);
                    }
                }
            });
            $remove.on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();

                $.ajax({
                    url: Indra.ajax.delete_img + id,
                    type: 'POST',
                    error: function (jqXHR, textStatus) {
                        $error.text('An error occured');
                    }
                }).done(function () {
                    $self.removeClass('complete');
                    $self.removeClass('loading');
                    $input.val('');
                    $image.prop('src', '');
                    $update.attr('data-src', '');
                    resizeUploadZone($input);
                });
            });
            $edit.on('click', function (e) {
                e.preventDefault();
                e.stopPropagation();

                var full = $(this).data('full');
                $drawCover.data('image', full);
                $drawCover.css('background-image', 'url(' + full.base64 + ')');
                $drawCover.prop('width', full.width);
                $drawCover.prop('height', full.height);
                $drawCover.width(full.width);
                $drawCover.height(full.height);
                $drawingModal.modal('show');
            });
        });

        $(document).on("imageResized", function (event) {
            var $self = event.self;
            var id = $self.data('id');
            var $input = $('input[type="file"]', $self);
            var $image = $('[data-upload="img"]', $self);
            var $error = $('[data-upload="error"]', $self);
            var $edit = $('[data-upload="edit"]', $self);

            var $form = $('form[role="form"]');
            var formdata = (window.FormData) ? new FormData($form[0]) : null;
            var data = (formdata !== null) ? formdata : $form.first().serizalize();

            // Ajout de l'img resized au form que l'on va envoyer
            if (event.blob && event.url) {
                /*if (formdata && formdata.delete) {
                    for (var x = 1; x <=5; x++) {
                        // Suppression des images du form
                        formdata.delete('indra_ao[image_' + x + ']');
                    }
                }*/
                data.append('indra_ao[image_' + id + ']', event.blob);
            }

            $.ajax({
                url: Indra.ajax.upload_img + id,
                type: 'POST',
                contentType: false,
                processData: false,
                dataType: 'json',
                data: data,
                success: function (response) {
                    var data = response.data;

                    if (data.error) {
                        $error.text(data.error);
                        $self.removeClass('complete');
                        $self.removeClass('loading');
                    }

                    if (data.base64) {
                        $image.prop('src', data.base64);
                        $self.removeClass('loading');
                        $self.addClass('complete');
                    }

                    if (data.full) {
                        $edit.data('full', data.full);
                    }

                    $input.val('');
                    upload_img = false;

                    resizeUploadZone($input);
                },
                error: function (jqXHR, textStatus) {
                    $error.text('An error occured');
                }
            });
        });
        //==\ upload image

        /**
         * Function that resize the input files with the size of the div upload zones
         * @param $obj
         */
        function resizeUploadZone($obj)
        {
            var height = $obj.closest(".box-upload").children(".inner").outerHeight(true);
            var width = $obj.closest(".box-upload").children(".inner").outerWidth(true);
            $obj.css("height", height);
            $obj.css("width", width);
            $obj.css("position", 'absolute');
            $obj.css("top", 0);
            $obj.css("left", 0);
            $obj.css("cursor", "pointer");
        }

        /**
         * Function that resize the input files with the define size
         * @param $obj
         */
        function resizeUploadImage($file, self)
        {
            if ($file.type.match(/image.*/)) {
                if (window.FileReader) {
                    // Load the image
                    var reader = new FileReader();
                    reader.onload = function (readerEvent) {
                        var image = new Image();
                        image.onload = function (imageEvent) {
                            // Resize the image
                            var canvas = document.createElement('canvas'),
                                max_size = 544,// TODO : pull max size from a site config
                                width = image.width,
                                height = image.height;
                            if (width > height) {
                                if (width > max_size) {
                                    height *= max_size / width;
                                    width = max_size;
                                }
                            } else {
                                if (height > max_size) {
                                    width *= max_size / height;
                                    height = max_size;
                                }
                            }
                            canvas.width = width;
                            canvas.height = height;
                            canvas.getContext('2d').drawImage(image, 0, 0, width, height);
                            var dataUrl = canvas.toDataURL('image/png');
                            var resizedImage = dataURLToBlob(dataUrl);
                            $.event.trigger({
                                type: "imageResized",
                                blob: resizedImage,
                                url: dataUrl,
                                self: self
                            });
                        };
                        image.src = readerEvent.target.result;
                    };
                    reader.readAsDataURL($file);
                } else {
                    $.event.trigger({
                        type: "imageResized",
                        blob: null,
                        url: null,
                        self: self,
                        file: $file
                    });
                }
            }
        }

        /* Utility function to convert a canvas to a BLOB */
        var dataURLToBlob = function (dataURL) {
            var BASE64_MARKER = ';base64,';
            if (dataURL.indexOf(BASE64_MARKER) == -1) {
                var parts = dataURL.split(',');
                var contentType = parts[0].split(':')[1];
                var raw = parts[1];

                return new Blob([raw], {type: contentType});
            }

            var parts = dataURL.split(BASE64_MARKER);
            var contentType = parts[0].split(':')[1];
            var raw = window.atob(parts[1]);
            var rawLength = raw.length;

            var uInt8Array = new Uint8Array(rawLength);

            for (var i = 0; i < rawLength; ++i) {
                uInt8Array[i] = raw.charCodeAt(i);
            }

            return new Blob([uInt8Array], {type: contentType});
        }
    });
})(jQuery);
