/**
 * Created by gdahan on 05/12/2016.
 */

function isEdgeOrIE() {
    var match = navigator.userAgent.match(/(?:MSIE |Trident\/.*; rv:)(\d+)/);
    return (match || window.navigator.userAgent.indexOf("Edge") > -1) ? true : false;
}
if(!isEdgeOrIE()) {
    (function($){
        $(document).ready(function(){
            var $images = $("label.upload-area");
            var $attachementsContainerParent = $("[data-target='documents-container']");
            var $attachementsContainer = $("[data-target='documents-container-dropzone']");
            var $attachementsInput = $(".input-documents-dropzone");
            var $attachements = $attachementsContainer.children(".attached-document");
            var attachementCount = $attachements.length;
            var attachementLock = false;
            var enteredZone;

            // resize input file on page load
            $images.find("input").each(function () {
                resizeUploadZone($(this));
            });

            // resize input file when resize window
            $(window).resize(function () {
                $images.find("input").each(function () {
                    resizeUploadZone($(this));
                });
            });

            // Images drag n drop controllers

            $images.on('dragenter', function (e) {
                e.stopPropagation();
                e.preventDefault();
                $images.each(function () {
                    $(this).css('border', '4px dashed grey');
                    $(this).css('opacity', '0.5');
                });
                $(this).css('border', '4px dashed #97be0d');
                $(this).css('opacity', '1');
            });

            // Resize Images Input on change
            $images.on('change', function () {
                $images.find("input").each(function () {
                    resizeUploadZone($(this));
                });
            });

            // Attachements drag n drop controllers
            $attachementsContainer.on('dragenter', function (e) {
                e.stopPropagation();
                e.preventDefault();

                if ($(e.target).attr("data-target") == "documents-container-dropzone") {

                    //On recherche s'il n'existe pas déjà un input file vide
                    $create = true;
                    $el = null;
                    $('input[type="file"][id*="indra_ao_attachments"]').each(function ($i, $e) {
                        var file = this.files[0];
                        var file_name_input = $(this).siblings("div").find("input[type='text'][data-target='document-name']");
                        if (!file && file_name_input.val() == "") {
                            $create = false;
                            $el = $($e);
                            return false;
                        }
                    });

                    //On ne crée une zone que s'il n'existe pas un input vide
                    //Sinon on agrandit le file input de celui existant
                    if ($create) {
                        $(".add-attached-document").trigger("click");
                        attachementLock = true;
                        //Récupère l'id du input file
                        $idFileInput = $("[data-target='documents-container'] > .attached-document.on").size() - 1;
                        $el = $("#indra_ao_attachments_" + $idFileInput + "_path");
                    }
                    $el.addClass("input-documents-dropzone");
                }
            });

            $attachementsInput.on('drop', function (e) {
                $(this).closest("label").addClass("complete");
            });

            // Behavior outside of the drop zones
            $(document).on('dragenter', function (e) {
                $images.css('opacity', '1');
                $images.css('border', '4px dashed grey');
                enteredZone = e.target;

                $nbInputEmpty = searchNumberOfAttachementsInputEmpty();
                $nbInput = $("[data-target='documents-container'] > .attached-document.on").size();
                if ($nbInput < 4 || ($nbInputEmpty != 0 && $nbInput >= 4 )) {
                    $attachementsContainer.css('border', '4px dashed grey');
                    $attachementsContainer.css("z-index", "10");
                }
            });

            $(document).on("dragover",function(e) {
                //S'il ne s'agit pas d'une zone de dépot on bloque le drop
                if($(e.target).attr("type") != "file") {
                    e.preventDefault();
                }
            });

            $(document).on('drop', function (e) {
                //S'il ne s'agit pas d'une zone de dépot on bloque le drop
                if($(e.target).attr("type") != "file") {
                    e.preventDefault();
                }
                $images.css('opacity', '1');
                $images.css('border', '0px dashed grey');
                $attachementsContainer.css('border', '0px dashed grey');
                $attachementsContainer.css("z-index", "-1");
            });

            $(document).on('dragexit', function (e) {
                $images.css('opacity', '1');
                $images.css('border', '0px dashed grey');
                $attachementsContainer.css('border', '0px dashed grey');
                $attachementsContainer.css("z-index", "-1");
            });

            $(document).on('dragleave', function (e) {
                if (e.originalEvent.clientX <= 0 || e.originalEvent.clientY <= 0 ||
                    e.originalEvent.clientY >= $(window).height() || e.originalEvent.clientX >= $(window).width()) {
                    $images.css('opacity', '1');
                    $images.css('border', '0px dashed grey');
                    $attachementsContainer.css('border', '0px dashed grey');
                    $attachementsContainer.css("z-index", "-1");
                    $('input[type="file"][id*="indra_ao_attachments"]').each(function ($i, $e) {
                        var file_name_input = $(this).siblings("div").find("input[type='text'][data-target='document-name']");
                        var file = this.files[0];

                        //On supprime si l'input ne contient pas de fichier
                        if (!file && file_name_input.val() == "") {
                            attachementLock = false;
                            $($e).parent().parent().children(".remove-document-container").find("[data-control='remove-document']").trigger('click');
                        }
                    });
                }
            });

            /**
             * Function that resize the input files with the size of the div upload zones
             * @param $obj
             */
            function resizeUploadZone($obj) {
                var height = $obj.closest(".box-upload").children(".inner").outerHeight(true);
                var width = $obj.closest(".box-upload").children(".inner").outerWidth(true);
                $obj.css("height", height);
                $obj.css("width", width);
				$obj.css("position", 'absolute');
				$obj.css("top", 0);
				$obj.css("left", 0);
				$obj.css("cursor", "pointer");
            }

            function searchNumberOfAttachementsInputEmpty() {
                $nb = 0;
                $('input[type="file"][id*="indra_ao_attachments"]').each(function ($i, $e) {
                    var file = this.files[0];
                    if (!file) {
                        $nb++;
                    }
                });
                return $nb;
            }
        });
    })(jQuery);
}