/**
 * Fonction d'autocomplete de recherche d'une plaque d'immatriculation
 */

(function ($) {
    var $numberPlateInput = $("[data-control='numberPlate']");

    $numberPlateInput.typeahead({
        minLength: 2,
        items: 25,
        delay: 100,
        source: function (query, process) {
            return $.get(Indra.ajax.autocomplete2 + query, {}, function (response) {
                for (var i in response.data) {
                    var item = response.data[i];
                    response.data[i].name = item.number_plate;
                }
                return process(response.data);
            }, 'json');
        }
    });
})(jQuery);
