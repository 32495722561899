(function ($) {
    $(document).ready(function () {
        var $collectionHolder;

        // setup an "add a emailsValidationCode" link
        var $addEmailsValidationCodeButton = $('<button type="button" class="add_emailsValidationCode_link btn btn-success">'+Indra.attachments.trans_button_add_email+'</button>');
        var $newLinkLi = $('<div class="button_add_email_validation_code"></div>').append($addEmailsValidationCodeButton);
        // Active l'info bulle
        $('.icon-information').tooltip();

        // Get the ul that holds the collection of emailsValidationCodes
        $collectionHolder = $('ul.emailsValidationCodeCollection');

        // add the "add a emailsValidationCode" anchor and li to the emailsValidationCodeCollection ul
        $collectionHolder.append($newLinkLi);

        // count the current form inputs we have (e.g. 2), use that as the new
        // index when inserting a new item (e.g. 2)
        $collectionHolder.data('index', $collectionHolder.find('input').length);

        $addEmailsValidationCodeButton.on('click', function (e) {
            // add a new emailsValidationCode form (see next code block)
            addEmailsValidationCodeForm($collectionHolder, $newLinkLi);
            $(this).parent().prev("li").children("div").addClass('block_form_group_email_validation_code')
        });

        // add a delete link to all of the existing emailsValidationCode form li elements
        $collectionHolder.find('li').each(function (index) {
            addEmailsValidationCodeFormDeleteLink($(this));
        });
    });
})(jQuery);

function addEmailsValidationCodeForm($collectionHolder, $newLinkLi)
{
    // Get the data-prototype explained earlier
    var prototype = $collectionHolder.data('prototype');

    // get the new index
    var index = $collectionHolder.data('index');

    // var newForm = '<div class="form-group ">'+prototype+'<\div>';
    var newForm = prototype;
    // You need this only if you didn't set 'label' => false in your emailsValidationCodeCollection field in TaskType
    // Replace '__name__label__' in the prototype's HTML to
    // instead be a number based on how many items we have
    // newForm = newForm.replace(/__name__label__/g, index);

    // Replace '__name__' in the prototype's HTML to
    // instead be a number based on how many items we have
    newForm = newForm.replace(/__name__/g, index);

    // increase the index with one for the next item
    $collectionHolder.data('index', index + 1);

    // Display the form in the page in an li, before the "Add a emailsValidationCode" link li
    var $newFormLi = $('<li></li>').append(newForm);
    $newLinkLi.before($newFormLi);


    // add a delete link to the new form
    addEmailsValidationCodeFormDeleteLink($newFormLi);
}

function addEmailsValidationCodeFormDeleteLink($emailsValidationCodeFormLi)
{
    var $removeFormButton = $('<button type="button" class="close" aria-label="Close"><span aria-hidden="true">&times;</span></button>');
    $emailsValidationCodeFormLi.append($removeFormButton);

    $removeFormButton.on('click', function (e) {
        // remove the li for the emailsValidationCode form
        $emailsValidationCodeFormLi.remove();
    });
}