/**
 * Fonction d'autocomplete de recherche d'une ville en fonction d'un code postal
 */

(function ($) {
    var $zipcodeInput = $("[data-control='zipCode']");
    var $cityInput = $("[data-target='city']");

    $zipcodeInput.typeahead({
        minLength: 2,
        items: 50,
        delay: 100,
        source: function (query, process) {
            if (!$.isNumeric(query)) {
                return [];
            }

            return $.get(Indra.ajax.autocomplete + query, {}, function (response) {
                for (var i in response.data) {
                    var item = response.data[i];
                    var city = item.city;
                    var zipcode = item.code;
                    response.data[i].name = "@city (@zipcode)".replace('@city', city).replace('@zipcode', zipcode);
                }

                return process(response.data);
            }, 'json');
        },
        updater: function (item) {
            item.name = item.code;
            return item;
        },
        afterSelect: function (item) {
            $("[data-target='city']").val(item.city);
        }
    });

    $zipcodeInput.on("keyup" , function(){
        if( $zipcodeInput.val().length != 5 &&  $("[data-target='city']").val() != "") {
            $("[data-target='city']").val("");
        }
    });

    $("[data-target='city']").prop('readonly', true);
})(jQuery);
