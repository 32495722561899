(function ($) {
    $(document).ready(function() {
        $('[data-toggle="tooltip"]').tooltip({
            container: 'body',
            placement: function(pop, ele) {
                if ($(window).width() < 768) {
                    return 'top';
                } else {
                    return 'right';
                }
            }
        });
        $('.form-error[data-toggle="tooltip"]').tooltip('show');
        var  tootltipHide = window.setTimeout(function() {
            $('.form-error[data-toggle="tooltip"]').tooltip('hide');
        }, 7000);

        $(window).resize(function () {
            $('[data-toggle="tooltip"]').tooltip('hide');
        });
    });
})(jQuery);